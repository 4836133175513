/* App.css */

.login-container {
  background-color: #000;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input {
  margin: 10px;
  padding: 8px;
  border-radius: 5px;
  border: none;
}

.login-button {
  background-color: #D43330;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #A3201A;
}

.error-message {
  color: #D43330;
  margin-top: 10px;
}
.btn-ryze {
  background: #c31432 !important;
  background: -webkit-linear-gradient(to right, #F69E23, #c31432) !important;
  background: linear-gradient(to right, #F69E23, #c31432) !important;
  border: none !important;
  color: #fff !important;
  font-weight: bold !important;
  transition: 1s !important;
  z-index: 3 !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}